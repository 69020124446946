<template>
  <div class="card" style="margin-top:0;">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
        <i class="material-icons">notes</i>
      </div>
      <h3 class="card-title">REPORTE DE FACTURACION</h3>
    </div>
    <div class="card-body">
      <v-row class="mx-0">
        <v-col cols="2">
          <v-select
            prepend-icon="mdi-navigation"
            v-model="opcionReporte"
            :items="[
              { id: 1, nombre: 'Calendario' },
              { id: 2, nombre: 'Financiero' },
            ]"
            item-text="nombre"
            item-value="id"
            label="Tipo de Reporte"
            color="blue darken-3"
          ></v-select>
        </v-col>
        <v-col cols="2" v-if="opcionReporte != 2">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="inicio_formated"
                label="Fecha de Inicio"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="inicio"
              @input="menu1 = false"
              locale="es-cl"
              prev-icon="chevron_left"
              next-icon="chevron_right"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" v-if="opcionReporte != 2">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="termino_formated"
                label="Fecha de termino"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="termino"
              @input="menu2 = false"
              locale="es-cl"
              prev-icon="chevron_left"
              next-icon="chevron_right"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" v-if="opcionReporte == 2">
          <v-select
            prepend-icon="mdi-navigation"
            v-model="cierre"
            :items="cierres"
            item-text="nombre"
            item-value="id"
            label="Elija periodo de cierre"
            color="blue darken-3"
            @change="setCierre"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="2">
          <!-- <v-select 
            prepend-icon="mdi-navigation"
            v-model="opcionFiltro"
            :items="[{id: 1, nombre: 'Todos'}, {id: 2, nombre: 'Cosecha'}, {id: 3, nombre: 'Smolt'}]"
            item-text="nombre"
            item-value="id"
            label="Filtro Reporte"
            color="blue darken-3"
          ></v-select> -->
          <v-select
            prepend-icon="mdi-navigation"
            v-model="opcionFiltro"
            :items="[
              { id: 0, nombre: 'Todos' },
              { id: 1, nombre: 'Cosecha' },
              { id: 3, nombre: 'Smolt' },
              ,
              { id: 4, nombre: 'Mortalidad' },
              { id: 5, nombre: 'Baño' },
              { id: 6, nombre: 'Vacuna' },
            ]"
            item-text="nombre"
            item-value="id"
            label="Filtro Reporte"
            color="blue darken-3"
          ></v-select>
        </v-col>
        <v-col class="text-right" cols="4">
          <v-btn color="blue darken-1" dark @click="loadFacturas"
            ><i class="material-icons">cached</i> Generar</v-btn
          >
          <v-btn class="mx-2" dark @click="download" color="green"
            ><i class="material-icons">text_snippet</i> Excel</v-btn
          >
          <!--<v-btn class="mx-2" dark @click="downloadPDF" color="#C62828"> <i class="material-icons">picture_as_pdf</i> PDF</v-btn>-->
        </v-col>
      </v-row>

      <spinner v-if="spinner"></spinner>

      <v-data-table
        :headers="facturasListHeaders"
        :items="facturasListBody"
        no-data-text="No hay partes"
        :search="search"
        fixed-header
        :height="table_height"
        :hide-default-footer="true"
        disable-pagination
      >
        <template v-slot:item.emision="{ item }">
          <span>{{ moment(item.emision).format("DD-MM-YYYY") }}</span>
        </template>
        <template v-slot:item.vencimiento="{ item }">
          <span v-if="item.vencimiento">{{
            moment(item.vencimiento).format("DD-MM-YYYY")
          }}</span>
        </template>
        <template v-slot:item.fecha="{ item }">
          <span v-if="item.fecha">{{
            moment(item.fecha).format("DD-MM-YYYY")
          }}</span>
        </template>
        <template v-slot:item.peces_centro="{ item }">
          {{ formatNumber(item.peces_centro, 0) }}
        </template>
        <template v-slot:item.talla_pez_centro="{ item }">
          {{ formatNumber(item.talla_pez_centro, 2) }}
        </template>
        <template v-slot:item.biomasa="{ item }">
          {{ formatNumber(item.biomasa, 0) }}
        </template>
        <template v-slot:item.tarifa_usd="{ item }">
          {{ formatNumber(item.tarifa_usd, 0) }}
        </template>
        <template v-slot:item.servicio_usd="{ item }">
          {{ formatNumber(item.servicio_usd, 0) }}
        </template>
      </v-data-table>
    </div>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.table-wellboat {
  font-size: 16pt;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}
.card {
  margin-top: 0;
}
.card .card-header-rose .card-icon {
  background: #263238;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(32, 30, 31, 0.822);
}
</style>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    spinner: false,
    opcionReporte: 1,
    opcionFiltro: 0,
    color: "green",
    snackbar: false,
    validation_message: "",
    search: null,
    isLoading: false,
    empresas: [],
    inicio: "",
    termino: "",
    menu1: false,
    menu2: false,
    url_excel: "",
    url_pdf: "",
    table_height: 600,
    cierre: 0,
    cierres: [],
    facturasListHeaders: [
      {
        text: "Año",
        align: "center",
        sortable: true,
        value: "year",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Mes",
        align: "center",
        sortable: true,
        value: "month",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Cliente",
        align: "center",
        sortable: true,
        value: "empresa.razon_social",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Prefactura",
        align: "center",
        sortable: true,
        value: "numero",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Fecha Emisión",
        align: "center",
        sortable: true,
        value: "emision",
        dataType: "Date",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Fecha Vencimiento",
        align: "center",
        sortable: true,
        value: "vencimiento",
        dataType: "Date",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Fecha Viaje",
        align: "center",
        sortable: true,
        value: "fecha",
        dataType: "Date",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Wellboat",
        align: "center",
        sortable: true,
        value: "wellboat",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Guía Despacho",
        align: "center",
        sortable: true,
        value: "guia",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Centro Origen",
        align: "center",
        sortable: true,
        value: "centro",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Jaula Origen",
        align: "center",
        sortable: true,
        value: "jaula_origen",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Centro Destino",
        align: "center",
        sortable: true,
        value: "acopio",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Jaula Destino",
        align: "center",
        sortable: true,
        value: "jaula_destino",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Especie",
        align: "center",
        sortable: true,
        value: "especie",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Peces Wellboat",
        align: "center",
        sortable: true,
        value: "peces_centro",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Talla Pez Informe Centro (Kg)",
        align: "center",
        sortable: true,
        value: "talla_pez_centro",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Total Carga Biomasa (Kg)",
        align: "center",
        sortable: true,
        value: "biomasa",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Carga Espera",
        align: "center",
        sortable: true,
        value: "carga_espera",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Carga Duracion",
        align: "center",
        sortable: true,
        value: "carga_duracion",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Traslado Espera",
        align: "center",
        sortable: true,
        value: "traslado_espera",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Traslado Duracion",
        align: "center",
        sortable: true,
        value: "traslado_duracion",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Descarga Espera",
        align: "center",
        sortable: true,
        value: "descarga_espera",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Descarga Duracion",
        align: "center",
        sortable: true,
        value: "descarga_duracion",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Total",
        align: "center",
        sortable: true,
        value: "tiempo_total",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tarifa Viaje USD",
        align: "center",
        sortable: true,
        value: "tarifa_usd",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Demurrage USD",
        align: "center",
        sortable: true,
        value: "demora_usd",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Servicio USD",
        align: "center",
        sortable: true,
        value: "servicio_usd",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Digitar MN",
        align: "center",
        sortable: true,
        value: "digitar_millas",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Millas Nauticas",
        align: "center",
        sortable: true,
        value: "millas",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Observaciones",
        align: "center",
        sortable: true,
        value: "observaciones",
        width: "300px",
        class: "blue-grey darken-4 white--text",
      },
    ],
    facturasListBody: [],
  }),
  mounted() {
    this.inicio = this.moment().format("2020-11-01"); // this.moment().format("YYYY-MM-01");
    this.termino = this.moment().format("YYYY-MM-DD");
    this.loadEmpresas();
    //this.loadFacturas();
    if (window.innerHeight < 768) {
      this.table_height = 450;
    }
    this.loadCierres();
  },
  methods: {
    async loadEmpresas() {
      let url = `${this.base_url}empresas`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.empresas = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadFacturas() {
      this.spinner = true;
      this.url_excel = "";
      this.url_pdf = "";
      this.facturasListBody = [];
      const cierre_id = this.opcionReporte == 1 
                          ? 'undefined'
                          : this.cierre.id;
      let url = `${this.base_url}facturas/porfecha/${this.inicio}/${this.termino}/${cierre_id}/${this.opcionFiltro}`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.facturasListBody = response.data.body;
          //this.url_excel = this.storage_url + response.data.url_excel;
          this.url_excel = response.data.url_excel;
          this.url_pdf = this.storage_url + response.data.url_pdf;
          this.showSnackBar(false);
        })
        .catch((error) => {
          console.log(error);
        });
      this.spinner = false;
    },
    async loadCierres() {
      let url = `${this.base_url}cierres/lista/select`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.cierres = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    download() {
      window.open(this.url_excel);
    },
    downloadPDF() {
      window.open(this.url_pdf);
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },
    ...mapMutations(["actualizarBaseUrl", "mostrarLoading", "ocultarLoading"]),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    setCierre() {
      this.inicio = this.formatIso(this.cierre.inicio);
      this.termino = this.formatIso(this.cierre.fin);
      if (this.opcionReporte == 1) this.cierre = {};
    },
    formatIso(fecha) {
      let arrF = fecha.split("-");
      return `${arrF[2]}-${arrF[1]}-${arrF[0]}`;
    },
    formatNumber(numero, decimales = 2) {
      //, checknegative = false){
      //if(checknegative && numero < 0) return 'Número Inválido';
      if (!numero) return numero;
      let arrNum = numero.toString().split(".");
      if (arrNum[1] == undefined) arrNum[1] = "";
      let num = arrNum[0];
      if (!isNaN(num)) {
        num = num
          .split("")
          .reverse()
          .join("")
          .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
        num = num
          .split("")
          .reverse()
          .join("")
          .replace(/^[.]/, "");
        if (decimales > 0) num += ",";
        return num + arrNum[1].padEnd(decimales, "0");
      } else {
        return "0";
      }
    },
  },
  computed: {
    ...mapState(["storage_url", "loading", "base_url", "headers", "user"]),
    inicio_formated() {
      return this.formatDate(this.inicio);
    },
    termino_formated() {
      return this.formatDate(this.termino);
    },
  },
  components: {},
};
</script>
